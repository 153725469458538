import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
  Select,
} from 'semantic-ui-react';
import { getDeviceProperties, setDeviceProperties } from '../services/Settings';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import { getRole } from '../services/Auth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError } from '../components/Tools/NotificationToasts';
import InactivityHandler from '../components/Tools/InactivityHandler';
import { getLocalServerConfig } from '../services/Server';

const deviceTypes = [
  { key: 'freebox', value: 'freebox', text: 'Freebox' },
  { key: 'synology', value: 'synology', text: 'Synology' },
];

const protocolTypes = [
  { key: 'https', value: 'https', text: 'https:// (secure)' },
  { key: 'http', value: 'http', text: 'http:// (insecure)' },
];

function SettingsEditDevicePage() {
  const { id } = useParams();
  const [state, setState] = useState({
    item: {},
    loading: true,
    error: false,
    reason: '',
  });

  const getItem = useCallback(() => {
    getDeviceProperties(id)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const item = response.data.result;
          item.password = '';
          setState({
            loading: false,
            item,
            error: false,
            reason: '',
          });
        } else
          setState({
            loading: false,
            item: {},
            error: true,
            reason:
              response.status +
              (response.data.message !== undefined ? ` ${response.data.message}` : ''),
          });
      })
      .catch((e) => {
        setState({
          loading: false,
          item: {},
          error: true,
          reason: e.message,
        });
      });
  }, [id]);

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Modifier l'appareil`;
    getItem();
  }, [getItem]);

  const handleEnabled = () => {
    const { item } = state;
    item.enabled = !item.enabled;
    setState((prevState) => ({ ...prevState, ...item }));
  };

  const handleInputChange = (key, val) => {
    const { item } = state;
    item[key] = val;
    setState((prevState) => ({ ...prevState, ...item }));
  };

  const handleSave = () => {
    setDeviceProperties({
      id: state.item.id,
      name: state.item.name,
      enabled: state.item.enabled,
      login: state.item.login,
      password: state.item.password,
      port: state.item.port,
      address: state.item.address,
      protocol: state.item.protocol,
      type: state.item.type,
      notification_token: state.item.notification_token,
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({ ...prevState, redirect: true }));
        } else {
          console.log(response.data);
          notifError(`Echec de l'enregistrement: ${response.data}`);
        }
      })
      .catch((e) => {
        console.log(e.message);
        notifError(`Echec de l'enregistrement: ${e.message}`);
      });
  };

  return (
    <InactivityHandler>
      <BackgroundRandomizer>
        {state.redirect && (
          <Navigate
            to={{
              pathname: getRole() > 1 ? '/settings' : '/devices',
              state: { activeIndex: 1 },
            }}
          />
        )}
        <div className="box">
          <NavBarAuth />
          <ToastContainer />
          <Grid
            textAlign="center"
            style={{ overflow: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 600 }}>
              <Segment className="transparent-table">
                <Header as="h2" color="teal" icon textAlign="center">
                  <Icon name="pencil" circular />
                  Modification de l&apos;appareil {state.item.name}
                </Header>
                {state.error ? (
                  <Segment color="red">
                    <span>
                      Une erreur est survenue : {state.reason}
                      <br />
                    </span>
                  </Segment>
                ) : null}
                {state.loading ? (
                  <Segment>
                    <Dimmer active inverted>
                      <Loader active size="small" />
                    </Dimmer>
                    <span>
                      <br />
                    </span>
                  </Segment>
                ) : (
                  <Form>
                    <Form.Field>
                      <label>Nom (au moins 2 caractères)</label>
                      <input
                        aria-labelledby="Nom"
                        label="Nom"
                        placeholder="Nom"
                        name="name"
                        value={state.item.name}
                        onChange={(val) => {
                          handleInputChange('name', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Type</label>
                      <Select
                        name="type"
                        placeholder={"Type d'appareil"}
                        options={deviceTypes}
                        value={state.item.type}
                        onChange={(ev, val) => {
                          handleInputChange('type', val.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Protocole</label>
                      <Select
                        name="protocol"
                        placeholder="Protocol"
                        options={protocolTypes}
                        value={state.item.protocol}
                        onChange={(ev, val) => {
                          handleInputChange('protocol', val.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        Adresse (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://fr.wikipedia.org/wiki/Fully_qualified_domain_name"
                        >
                          FQDN
                        </a>{' '}
                        ou IP)
                      </label>
                      <input
                        placeholder="mabox.freebox.fr"
                        name="address"
                        autoComplete="new-password"
                        value={state.item.address}
                        onChange={(val) => {
                          handleInputChange('address', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Port (1 - 65535)</label>
                      <input
                        placeholder="43785"
                        name="port"
                        type="number"
                        min="1"
                        max="65535"
                        value={state.item.port}
                        onChange={(val) => {
                          handleInputChange('port', val.target.value);
                        }}
                      />
                    </Form.Field>
                    {state.item.type !== 'freebox' && (
                      <Form.Field>
                        <label>Login</label>
                        <input
                          placeholder="Login"
                          name="login"
                          autoComplete="new-password"
                          value={state.item.login}
                          onChange={(val) => {
                            handleInputChange('login', val.target.value);
                          }}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <label>
                        {state.item.type === 'freebox' ? 'Token API Freebox' : 'Password'}
                      </label>
                      <input
                        placeholder="(Laisser vide pour ne pas modifier)"
                        name="password"
                        autoComplete="new-password"
                        onChange={(val) => {
                          handleInputChange('password', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        Topic de notification{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.ntfy.sh/">
                          Ntfy
                        </a>
                      </label>
                      <input
                        placeholder="XuIyDYTw...."
                        name="notification_token"
                        autoComplete="new-password"
                        value={
                          state.item.notification_token !== null
                            ? state.item.notification_token
                            : ''
                        }
                        onChange={(val) => {
                          handleInputChange('notification_token', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>{state.item.enabled ? 'Activé' : 'Désactivé'}</label>
                      <Checkbox
                        slider
                        name="enabled"
                        checked={state.item.enabled}
                        onClick={() => handleEnabled()}
                      />
                    </Form.Field>
                  </Form>
                )}
              </Segment>
              <Button
                size="large"
                label="Enregistrer"
                color="teal"
                labelPosition="right"
                icon="save"
                onClick={handleSave}
              />
              <Button
                label="Annuler"
                labelPosition="right"
                icon="close"
                size="large"
                as={Link}
                to={getRole() > 1 ? '/settings' : '/devices'}
              />
            </Grid.Column>
          </Grid>
        </div>
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default SettingsEditDevicePage;
