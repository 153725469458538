import React, { useState } from 'react';
import { Icon, Popup, Progress, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { firstCharUp, millisecondsToStr, oneDayAgo, rmBrackets } from '../Tools/Utils';
import { Media } from '../Tools/AppMedia';
import {
  buildActionCell,
  buildActionCellResponsive,
  buildStateCell,
  cloudAction,
  cloudAddCategory,
  cloudAddNotification,
} from './CloudFunctions';
import { getRole } from '../../services/Auth';
import CloudDeleteModal from '../Modal/CloudDeleteModal';

function DirectoryCloudTableRow({ item }) {
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const desktopRow = (i) => {
    return (
      <>
        <Table.Cell className="td-center">{buildActionCell(i)}</Table.Cell>
        <Table.Cell className="td-center">
          <span id={`torrent-state-cell-${i.hash}`}>{buildStateCell(i)}</span>
          <Tooltip
            anchorId={`torrent-state-cell-${i.hash}`}
            effect="solid"
            place="right"
            content={`Status: ${i.state}`}
          />
        </Table.Cell>
        <Table.Cell className="td-center" />
        <Table.Cell className="td-left">
          <span id={`torrent-name-cell-${i.hash}`}>
            {firstCharUp(rmBrackets(rmBrackets(i.name)))}
            {i.category !== '' && <span className="text-success"> [{i.category}]</span>}
          </span>
          <Tooltip
            anchorId={`torrent-name-cell-${i.hash}`}
            effect="solid"
            place="bottom"
            content={i.name}
          />
          {i.progress !== 1 && getRole() > 1 && (
            <>
              <Icon
                name={i.category === '' ? 'plus circle' : 'minus circle'}
                color={i.category === '' ? 'green' : 'red'}
                id={`torrent-category-cell-${i.hash}`}
                className="clickable clickable-color"
                onClick={() => {
                  cloudAddCategory(i.hash, i.category === '' ? 'Auto' : '');
                }}
              />
              <Tooltip
                anchorId={`torrent-category-cell-${i.hash}`}
                effect="solid"
                place="right"
                content={`${i.category === '' ? 'Ajouter' : 'Retirer'} la catégorie Auto`}
              />
            </>
          )}
          {i.progress !== 1 && (
            <>
              <Icon
                name="bell"
                color={i.notification ? 'yellow' : 'grey'}
                id={`torrent-category-bell-${i.hash}`}
                className="clickable clickable-color"
                onClick={() => cloudAddNotification(i.hash, !i.notification)}
              />
              <Tooltip
                anchorId={`torrent-category-bell-${i.hash}`}
                effect="solid"
                place="right"
                content={i.notification ? 'Ne pas être notifié(e)' : 'Être notifié(e)'}
              />
            </>
          )}
        </Table.Cell>
        <Table.Cell className="td-center progress-bar-fixed-size-100">
          {i.state !== 'moving' ? (
            <Progress
              className="progress-bar-no-margin"
              progress
              percent={(i.progress * 100).toFixed(0)}
              active={i.state === 'downloading'}
              color={i.state === 'downloading' ? 'green' : 'orange'}
              size="small"
            />
          ) : (
            <>
              <Icon name="spinner" size="large" color="teal" loading /> Copie en cours
            </>
          )}
        </Table.Cell>
        <Table.Cell className="td-center">
          {i.dlspeed !== 0 ? `${(i.dlspeed / 1000).toFixed(0)} ko/s` : '-'}
        </Table.Cell>
        <Table.Cell className="td-center">
          {i.progress !== 1 && i.state !== 'moving' ? millisecondsToStr(i.eta * 1000) : '-'}
        </Table.Cell>
        <Table.Cell className="td-right font-weight-bolder">
          {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
        </Table.Cell>
        <Table.Cell className="td-center">
          {oneDayAgo(i.completion_on * 1000) && i.progress === 1 && getRole() < 2 ? (
            <>
              <Icon
                id={`prevent-torrent-delete-cell-${i.hash}`}
                className="clickable"
                name="trash alternate outline"
                color="blue"
                onClick={() => {
                  setOpen(true);
                }}
              />
              <Tooltip
                anchorId={`prevent-torrent-delete-cell-${i.hash}`}
                effect="solid"
                place="left"
                content="Supprimer torrent + fichiers récents"
              />
            </>
          ) : (
            <>
              <Icon
                id={`torrent-delete-cell-${i.hash}`}
                className="clickable"
                name="trash alternate outline"
                color="red"
                onClick={() => {
                  setOpen(true);
                }}
              />
              <Tooltip
                anchorId={`torrent-delete-cell-${i.hash}`}
                effect="solid"
                place="left"
                content="Supprimer torrent + fichiers"
              />
            </>
          )}
        </Table.Cell>
      </>
    );
  };

  const mobileRow = (i) => {
    return (
      <>
        <Table.Cell>
          <div>
            <div className="left">
              <span
                style={{ wordBreak: 'break-all' }}
                className="font-weight-bolder"
                id={`directory-cloud-name-cell-${i.hash}`}
              >
                {firstCharUp(rmBrackets(rmBrackets(i.name)))}
                {i.category !== '' && <span className="text-success"> [{i.category}]</span>}
              </span>
              <Tooltip
                anchorId={`directory-cloud-name-cell-${i.hash}`}
                effect="solid"
                place="bottom"
                content={i.name}
              />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ float: 'left', position: 'relative', width: '5%' }}>
              <span id={`directory-cloud-state-cell-${i.hash}`}>{buildStateCell(i)}</span>
              <Tooltip
                anchorId={`directory-cloud-state-cell-${i.hash}`}
                effect="solid"
                place="bottom"
                content={i.state}
              />
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              {i.state !== 'moving' ? (
                <Progress
                  className="progress-bar-no-margin-responsive"
                  progress
                  percent={(i.progress * 100).toFixed(0)}
                  active={i.state === 'downloading'}
                  color={i.state === 'downloading' ? 'green' : 'orange'}
                  size="small"
                />
              ) : (
                <span style={{ marginLeft: '5px' }}>
                  Copie <Icon name="spinner" color="teal" loading />
                </span>
              )}
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span id={`directory-cloud-eta-cell-${i.hash}`}>
                <Icon name="clock outline" />
                {i.progress !== 1 && i.state !== 'moving' ? millisecondsToStr(i.eta * 1000) : '-'}
              </span>
              <Tooltip
                anchorId={`directory-cloud-eta-cell-${i.hash}`}
                effect="solid"
                place="bottom"
                content="Temps restant"
              />
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          <Popup
            on={['click']}
            position="left center"
            hideOnScroll
            header="Actions:"
            size="large"
            open={openPopup}
            onOpen={() => setOpenPopup(true)}
            onClose={() => setOpenPopup(false)}
            trigger={
              <Icon className="clickable" name="angle double right" color="teal" size="big" />
            }
            content={buildActionCellResponsive(i, setOpen, setOpenPopup)}
          />
        </Table.Cell>
      </>
    );
  };

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? <Table.Row>{desktopRow(item)}</Table.Row> : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? <Table.Row>{mobileRow(item)}</Table.Row> : null;
        }}
      </Media>
      <CloudDeleteModal
        open={open}
        item={item}
        recent={oneDayAgo(item.completion_on * 1000)}
        setOpen={setOpen}
        cloudAction={cloudAction}
      />
    </>
  );
}

function DirectoryCloudTable({ cloudContent }) {
  return cloudContent.map((item) => {
    return <DirectoryCloudTableRow key={`directory-cloud-table-row-${item.hash}`} item={item} />;
  });
}

export default DirectoryCloudTable;
