import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import qs from 'qs';
import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  ReplayControl,
  ForwardControl,
  PlaybackRateMenuButton,
} from 'video-react';
import { Navigate, useLocation } from 'react-router';
import { Base64 } from 'js-base64';
import { Tooltip } from 'react-tooltip';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import 'video-react/dist/video-react.css';
import { getLocalServerConfig } from '../services/Server';

const { REACT_APP_API_URL: apiUrl } = process.env;

function StreamingPage() {
  const location = useLocation();
  let refInterval = 0;
  const playerRef = useRef(null);
  const [state, setState] = useState({
    link: undefined,
    title: '... Chargement ...',
    redirect: false,
    error: false,
  });

  const getVideo = () => {
    try {
      const link = qs.parse(location.search, { ignoreQueryPrefix: true }).q;
      const title = JSON.parse(decodeURIComponent(Base64.decode(link))).filename;
      setState((prevState) => ({ ...prevState, link, title }));
    } catch {
      setState((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  };

  const handleState = (playerState) => {
    const error = ![null, undefined].includes(playerState.error);
    if (error !== state.error) {
      console.log(`Error: ${playerState.error}`);
      setState((prevState) => ({
        ...prevState,
        error,
      }));
    }
  };

  const registerListener = () => {
    if (playerRef.current !== null) {
      playerRef.current.subscribeToStateChange(handleState);
      clearInterval(refInterval);
    }
  };

  const handleRedirect = () => {
    setState((prevState) => ({ ...prevState, redirect: true }));
  };

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Streaming`;
    refInterval = setInterval(registerListener, 10);
    getVideo();
    return () => {
      clearInterval(refInterval);
    };
  }, []);

  return (
    <BackgroundRandomizer>
      {state.redirect && <Navigate to="/" />}
      <NavBarAuth />
      <br />
      <Grid textAlign="center" style={{ maxHeight: '10vh' }}>
        <Grid.Column style={{ maxWidth: '110vh' }}>
          <Segment textAlign="center" className="transparent-table">
            <Icon size="large" id="stream-popup" name="warning sign" color="teal" />
            <Tooltip
              anchorId="stream-popup"
              effect="solid"
              content={
                <span>
                  Certains formats sont partiellement pris en charge. Il est possible que ni
                  l&apos;audio, ni la vidéo ne fonctionnent.
                </span>
              }
              place="bottom"
            />{' '}
            {'  '} <a href={`${apiUrl}/api/v1/server/download?key=${state.link}`}>{state.title}</a>
          </Segment>
        </Grid.Column>
      </Grid>
      <Grid textAlign="center" style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
        <Grid.Column style={{ maxWidth: '145vh' }}>
          <Segment className="transparent-table">
            {state.link !== undefined && !state.error ? (
              <Player
                ref={playerRef}
                autoPlay
                src={`${apiUrl}/api/v1/server/download?key=${state.link}`}
              >
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar autoHide={false}>
                  <ReplayControl seconds={30} order={2.1} />
                  <ReplayControl seconds={5} order={2.2} />
                  <ForwardControl seconds={5} order={3.1} />
                  <ForwardControl seconds={30} order={3.2} />
                  <PlaybackRateMenuButton rates={[5, 2, 1.2, 1, 0.8, 0.5, 0.1]} order={6.1} />
                </ControlBar>
              </Player>
            ) : (
              <>
                <Header icon>
                  <br />
                  <Icon name="broken chain" color="orange" />
                  <br />
                  Lien invalide ou format incompatible:
                  <br />
                  Impossible de charger ce fichier
                  <br />
                  {state.title.endsWith('.mkv') &&
                    'Les fichiers .mkv ne peuvent pas être lus sur Firefox'}
                  <br />
                </Header>
                <Grid>
                  <Grid.Row centered>
                    <Button
                      icon="home"
                      label="Accueil"
                      labelPosition="right"
                      color="teal"
                      onClick={handleRedirect}
                    />
                  </Grid.Row>
                </Grid>
              </>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </BackgroundRandomizer>
  );
}

export default StreamingPage;
