import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';

function SearchTableLinkButton({ item }) {
  const id = encodeURI(item.guid || item.link);

  return (
    item.infoUrl.startsWith('http') && (
      <div style={{ textAlign: 'center' }}>
        <a
          id={`search-item-external-link-${id}`}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://dereferer.me/?${item.infoUrl}`}
        >
          <Icon name="external" color="teal" />
        </a>
        <Tooltip
          anchorId={`search-item-external-link-${id}`}
          effect="solid"
          place="top"
          content={`Description du torrent sur ${item.indexerName} (prudence)`}
        />
      </div>
    )
  );
}

export default SearchTableLinkButton;
