import React from 'react';
import { Base64 } from 'js-base64';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';
import {
  torrentAction,
  torrentAddHashTracking,
  torrentSetCategory,
  torrentDeleteHashTracking,
} from '../../services/Torrent';
import { getRole } from '../../services/Auth';
import { oneDayAgo } from '../Tools/Utils';

const cloudAction = (hash, action, name) => {
  torrentAction({ hash, action, name })
    .then((response) => {
      if (response.status === 200 && response.data.success) notifSuccess('Action effectuée');
      else notifError("Échec de l'action");
    })
    .catch(() => {
      notifError("Échec de l'action");
    });
};

const cloudAddNotification = (hash, enabled) => {
  if (enabled)
    torrentAddHashTracking({ hash })
      .then((response) => {
        if (response.status === 200 && response.data.success) notifSuccess('Notification activée');
        else notifError("Échec d'ajout de notification");
      })
      .catch(() => {
        notifError("Échec d'ajout de notification");
      });
  else
    torrentDeleteHashTracking({ hash })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          notifSuccess('Notification désactivée');
        else notifError('Échec de suppression de notification');
      })
      .catch(() => {
        notifError('Échec de suppression de notification');
      });
};

const cloudAddCategory = (hash, category) => {
  torrentSetCategory({ hash, category })
    .then((response) => {
      if (response.status === 200 && response.data.success) notifSuccess('Catégorie modifiée');
      else notifError("Échec de l'action");
    })
    .catch(() => {
      notifError("Échec de l'action");
    });
};

const buildActionCell = (item) => {
  const id = Base64.encode(item.hash, true);
  switch (item.state) {
    case 'pausedUP':
    case 'pausedDL':
      return (
        <>
          <Icon
            id={`torrent-action-cell-${id}`}
            name="play"
            color="green"
            className="clickable"
            onClick={() => {
              cloudAction(item.hash, 'start', item.name);
            }}
          />
          <Tooltip
            anchorId={`torrent-action-cell-${id}`}
            effect="solid"
            place="right"
            content="Démarrer"
          />
        </>
      );
    case 'queuedUP':
    case 'queuedDL':
    case 'uploading':
    case 'stalledUP':
    case 'checkingUP':
    case 'checkingDL':
    case 'downloading':
    case 'stalledDL':
    case 'metaDL':
    case 'missingFiles':
    case 'error':
    case '':
    default:
      return (
        <>
          <Icon
            id={`torrent-action-cell-${id}`}
            name="stop circle"
            color="red"
            className="clickable"
            onClick={() => {
              cloudAction(item.hash, 'stop', item.name);
            }}
          />
          <Tooltip
            anchorId={`torrent-action-cell-${id}`}
            effect="solid"
            place="right"
            content="Mettre en pause"
          />
        </>
      );
  }
};

const buildActionCellResponsive = (item, setOpen, setOpenPopup) => {
  switch (item.state) {
    case 'pausedUP':
    case 'pausedDL':
      return (
        <>
          <br />
          <span
            className="clickable clickable-color"
            onClick={() => {
              cloudAction(item.hash, 'start', item.name);
              setOpenPopup(false);
            }}
          >
            <Icon name="play" size="large" color="green" /> Start
          </span>
          <br />
          <br />
          {item.progress !== 1 && getRole() > 1 && (
            <>
              <span
                className="clickable clickable-color"
                onClick={() => {
                  cloudAddCategory(item.hash, item.category === '' ? 'Auto' : '');
                  setOpenPopup(false);
                }}
              >
                <Icon
                  name={item.category === '' ? 'plus circle' : 'minus circle'}
                  size="large"
                  color={item.category === '' ? 'green' : 'red'}
                />{' '}
                Catégorie Auto
              </span>
              <br />
              <br />
            </>
          )}
          <>
            <span
              className="clickable clickable-color"
              onClick={() => {
                cloudAddNotification(item.hash, !item.notification);
                setOpenPopup(false);
              }}
            >
              <Icon name="bell" size="large" color={item.notification ? 'yellow' : 'grey'} />{' '}
              {item.notification ? 'Ne pas être notifié(e)' : 'Être notifié(e)'}
            </span>
            <br />
            <br />
          </>
          {oneDayAgo(item.completion_on * 1000) && item.progress === 1 && getRole() < 2 ? (
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon name="trash alternate outline" size="large" color="blue" /> Supprimer
            </span>
          ) : (
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon name="trash alternate outline" size="large" color="red" /> Supprimer
            </span>
          )}
          <br />
        </>
      );
    case 'queuedUP':
    case 'queuedDL':
    case 'uploading':
    case 'stalledUP':
    case 'checkingUP':
    case 'checkingDL':
    case 'downloading':
    case 'stalledDL':
    case 'metaDL':
    case 'missingFiles':
    case 'error':
    case '':
    default:
      return (
        <>
          <br />
          <span
            className="clickable clickable-color"
            onClick={() => {
              cloudAction(item.hash, 'stop', item.name);
              setOpenPopup(false);
            }}
          >
            <Icon name="stop circle" size="large" color="red" /> Stop
          </span>
          <br />
          <br />
          {item.progress !== 1 && getRole() > 1 && (
            <>
              <span
                className="clickable clickable-color"
                onClick={() => {
                  cloudAddCategory(item.hash, item.category === '' ? 'Auto' : '');
                  setOpenPopup(false);
                }}
              >
                <Icon
                  name={item.category === '' ? 'plus circle' : 'minus circle'}
                  size="large"
                  color={item.category === '' ? 'green' : 'red'}
                />{' '}
                Catégorie Auto
              </span>
              <br />
              <br />
            </>
          )}
          {oneDayAgo(item.completion_on * 1000) && item.progress === 1 && getRole() < 2 ? (
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon name="trash alternate outline" size="large" color="blue" /> Supprimer
            </span>
          ) : (
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon name="trash alternate outline" size="large" color="red" /> Supprimer
            </span>
          )}
          <br />
        </>
      );
  }
};

const buildStateCell = (item) => {
  switch (item.state) {
    case 'pausedUP':
    case 'pausedDL':
      return item.progress === 1 ? (
        <Icon color="grey" name="upload" />
      ) : (
        <Icon color="orange" name="cloud download" />
      );
    case 'moving':
      return <Icon color="orange" name="copy outline" />;
    case 'stalledDL':
      return <Icon className="blink" color="grey" name="search" />;
    case 'checkingUP':
    case 'checkingDL':
      return <Icon color="grey" name="spinner" loading />;
    case 'queuedUP':
    case 'queuedDL':
    case 'uploading':
    case 'stalledUP':
    case 'downloading':
    case 'metaDL':
      return item.progress === 1 ? (
        <Icon name="upload" />
      ) : (
        <Icon color="teal" name="cloud download" />
      );
    case 'missingFiles':
    case 'error':
      return <Icon name="ban" color="red" />;
    case '':
    default:
      return <Icon name="check circle" />;
  }
};

const buildTrState = (item) => {
  switch (item.state) {
    case 'queuedUP':
    case 'queuedDL':
    case 'uploading':
    case 'checkingUP':
    case 'checkingDL':
    case 'downloading':
    case 'metaDL':
      return 'positive';
    case 'stalledUP':
    case 'stalledDL':
      return 'warning';
    case 'missingFiles':
    case 'error':
      return 'negative';
    case 'pausedUP':
    case 'pausedDL':
    case '':
    default:
      return '';
  }
};

export {
  cloudAction,
  cloudAddCategory,
  cloudAddNotification,
  buildActionCell,
  buildActionCellResponsive,
  buildStateCell,
  buildTrState,
};
