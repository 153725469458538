import React, { useEffect, useState, useCallback } from 'react';
import { Button, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import { deleteDevice, getDeviceList } from '../services/Devices';
import { getLocalServerConfig } from '../services/Server';
import { getUserId } from '../services/Auth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError, notifSuccess, notifWarning } from '../components/Tools/NotificationToasts';
import InactivityHandler from '../components/Tools/InactivityHandler';
import DeviceDeleteModal from '../components/Modal/DeviceDeleteModal';
import DeviceTestConnectivityButton from '../components/Device/DeviceTestConnectivityButton';
import { testDeviceNotification } from '../services/Settings';

function DevicesPageTableRow({ device, getDevices }) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleDeleteDevice = (deviceId, name) => {
    deleteDevice(deviceId)
      .then((response) => {
        if (response.data.success) notifSuccess(`${name} supprimé`);
        else notifWarning(`Échec de suppression: ${response.data.message}`);
        getDevices();
      })
      .catch((e) => {
        notifWarning(`Échec de suppression de ${name}: ${e.message}`);
      });
  };

  const handleTestDeviceNotification = (deviceId) => {
    testDeviceNotification(deviceId)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Notification envoyée');
        } else notifError(`Échec d'envoi de la notification: ${response.data.message}`);
      })
      .catch((e) => {
        notifError(`Échec d'envoi de la notification: ${e.message}`);
      });
  };

  return (
    <>
      <Table.Row className={device.enabled ? '' : 'grey-text'}>
        <Table.Cell>{device.id}</Table.Cell>
        <Table.Cell>{device.type.charAt(0).toUpperCase() + device.type.slice(1)}</Table.Cell>
        <Table.Cell>{device.name}</Table.Cell>
        <Table.Cell>{`[id:${device.owner_id}] ${device.owner.nickname}`}</Table.Cell>
        <Table.Cell>
          {device.enabled ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}
        </Table.Cell>
        <Table.Cell>
          {device.logged_in || device.type === 'synology' ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="close" color="orange" />
          )}
          <DeviceTestConnectivityButton device={device} getDevices={getDevices} />
        </Table.Cell>
        <Table.Cell>
          {device.type === 'freebox'
            ? 'Authentification via token'
            : device.login === null
              ? '-'
              : device.login}
        </Table.Cell>
        <Table.Cell>
          {device.protocol}://{device.address}:{device.port}
        </Table.Cell>
        <Table.Cell>
          {getUserId() === device.owner_id ? (
            <>
              <Tooltip
                anchorId={`test-notification-device-${device.id}`}
                effect="solid"
                content="Envoyer un test"
                place="top"
              />
              <Button
                onClick={() => handleTestDeviceNotification(device.id)}
                color="green"
                id={`test-notification-device-${device.id}`}
                size="tiny"
                icon="send"
              />
            </>
          ) : (
            <>
              <Button
                color="grey"
                id={`test-notification-device-${device.id}`}
                size="tiny"
                icon="send"
              />
              <Tooltip
                anchorId={`test-notification-device-${device.id}`}
                effect="solid"
                place="top"
                content="Vous n'êtes pas propriétaire de cet appareil"
              />
            </>
          )}
          {device.notification_token}
        </Table.Cell>
        <Table.Cell>
          {getUserId() === device.owner_id ? (
            <>
              <Button
                color="teal"
                id={`edit-device-${device.id}`}
                as={Link}
                to={{
                  pathname: `/settings/devices/edit/${device.id}`,
                  state: { from: location.pathname },
                }}
                size="tiny"
                icon="pencil"
              />
              <Tooltip
                anchorId={`edit-device-${device.id}`}
                effect="solid"
                content="Modifier"
                place="top"
              />
              <Button
                onClick={() => setOpen(true)}
                color="red"
                id={`delete-device-${device.id}`}
                size="tiny"
                icon="trash alternate"
              />
              <Tooltip
                anchorId={`delete-device-${device.id}`}
                effect="solid"
                place="top"
                content="Supprimer définitivement"
              />
            </>
          ) : (
            <>
              <Button color="grey" id={`edit-device-${device.id}`} size="tiny" icon="pencil" />
              <Tooltip
                anchorId={`edit-device-${device.id}`}
                effect="solid"
                place="top"
                content="Vous n'êtes pas propriétaire de cet appareil"
              />
            </>
          )}
        </Table.Cell>
      </Table.Row>
      <DeviceDeleteModal
        open={open}
        setOpen={setOpen}
        deviceId={device.id}
        deviceName={device.name}
        handleDelete={handleDeleteDevice}
      />
    </>
  );
}

function DevicesPage() {
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  const location = useLocation();
  const [state, setState] = useState({
    devices: [],
    loading: true,
    error: false,
  });

  const getDevices = useCallback(() => {
    getDeviceList(true)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({
            ...prevState,
            devices: response.data.result,
            loading: false,
            error: false,
          }));
        } else {
          console.log('Could not get devices at the moment');
          console.log(response.status);
          setState((prevState) => ({ ...prevState, device: [], loading: false, error: true }));
        }
      })
      .catch((e) => {
        console.log(`Could not get devices: ${e.message}`);
        setState((prevState) => ({ ...prevState, devices: [], loading: false, error: true }));
      });
  }, []);

  useEffect(() => {
    document.title = `${siteName} | Mes Appareils`;
    if (getLocalServerConfig().devicesEnabled) getDevices();
    else setState((prevState) => ({ ...prevState, loading: false }));
  }, [getDevices]);

  const buildDeviceRow = state.devices.map((device) => {
    return <DevicesPageTableRow key={device.id} getDevices={getDevices} device={device} />;
  });

  return (
    <InactivityHandler>
      <BackgroundRandomizer>
        <NavBarAuth />
        <ToastContainer />
        <Segment
          className="transparent"
          textAlign="center"
          style={{ overflowY: 'scroll', height: '100vh' }}
        >
          {state.loading && (
            <>
              <br />
              <br />
              <Loader active size="huge">
                Chargement...
              </Loader>
            </>
          )}
          {!state.loading && (
            <Segment className="transparent-table" textAlign="center">
              <Header as="h2" icon color="teal">
                <Icon name="server" circular />
                <Header.Content>Appareils</Header.Content>
              </Header>
              <br />
              <Button
                color="green"
                icon="plus"
                label="Ajouter un appareil"
                labelPosition="left"
                as={Link}
                to={{ pathname: '/devices/new', state: { from: location.pathname } }}
              />
            </Segment>
          )}
          {!state.loading && state.devices.length > 0 && (
            <Segment className="transparent-table" style={{ overflowX: 'auto' }}>
              <Table basic="very" unstackable selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Nom</Table.HeaderCell>
                    <Table.HeaderCell>Propriétaire</Table.HeaderCell>
                    <Table.HeaderCell>Activé</Table.HeaderCell>
                    <Table.HeaderCell>Connecté</Table.HeaderCell>
                    <Table.HeaderCell>Login</Table.HeaderCell>
                    <Table.HeaderCell>Adresse</Table.HeaderCell>
                    <Table.HeaderCell>
                      Topic de notification (
                      <a target="_blank" rel="noopener noreferrer" href="https://ntfy.sh/">
                        Ntfy
                      </a>
                      )
                    </Table.HeaderCell>
                    <Table.HeaderCell>Modifier</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{buildDeviceRow}</Table.Body>
              </Table>
            </Segment>
          )}
          {!state.loading && state.devices.length === 0 && (
            <Segment>
              <Icon circular name="info" color="teal" />
              <br />
              <br /> Vous n&apos;avez pas encore d&apos;appareil associé à {siteName}
            </Segment>
          )}
          {state.error && (
            <Segment>
              <Icon name="exclamation" color="red" /> Nous ne pouvons pas accéder à la liste de vos
              appareils pour le moment, veuillez réessayer ultérieurement.
            </Segment>
          )}
          <Button
            icon="home"
            label="Accueil"
            labelPosition="right"
            as={Link}
            size="large"
            color="teal"
            to="/"
          />
          <Button
            icon="user"
            label="Mon Compte"
            labelPosition="right"
            as={Link}
            size="large"
            color="blue"
            to="/account"
          />
          <Button
            icon="mail"
            label="Nous Contacter"
            labelPosition="right"
            as={Link}
            size="large"
            color="grey"
            to="/contact"
          />
        </Segment>
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default DevicesPage;
