import React, { useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { Media } from '../Tools/AppMedia';
import { humanFileSize } from '../Tools/Utils';
import FreeboxDeleteFileModal from '../Modal/FreeboxDeleteFileModal';

function FreeboxFileExplorerTableRow({ item, device, directoryCallback, deleteCallback }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row>
              <Table.Cell className="td-center">
                {item.mimetype !== 'inode/directory' && <Icon name="file alternate outline" />}
              </Table.Cell>
              {item.mimetype === 'inode/directory' ? (
                <Table.Cell
                  className="td-left clickable text-primary"
                  onClick={() => {
                    directoryCallback(device.id, item.path);
                  }}
                >
                  {item.name}/
                </Table.Cell>
              ) : (
                <Table.Cell className="td-left">{item.name}</Table.Cell>
              )}
              <Table.Cell className="td-center">
                {item.mimetype === 'inode/directory' ? (
                  <Icon name="folder open outline" />
                ) : (
                  item.ext
                )}
              </Table.Cell>
              <Table.Cell className="td-center">
                {new Date(item.modification * 1000).toLocaleString()}
              </Table.Cell>
              <Table.Cell className="td-right font-weight-bolder">
                {humanFileSize(item.size)}
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon
                  id={`freebox-file-explorer-delete-cell-${item.index}`}
                  className="clickable"
                  color="red"
                  name="trash alternate outline"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Tooltip
                  anchorId={`freebox-file-explorer-delete-cell-${item.index}`}
                  effect="solid"
                  place="left"
                  content="Supprimer définitivement"
                />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row>
              <Table.Cell>
                {/* // Line 1 */}
                <div
                  className="td-center"
                  style={{
                    verticalAlign: 'middle',
                    float: 'left',
                    position: 'relative',
                    width: '10%',
                  }}
                >
                  {item.mimetype === 'inode/directory' ? (
                    <Icon name="folder open" size="large" />
                  ) : (
                    <Icon name="file alternate outline" size="large" />
                  )}
                </div>
                {/* // Line 2 */}
                <div style={{ float: 'left', position: 'relative', width: '80%' }}>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '100%' }}
                  >
                    {item.mimetype === 'inode/directory' ? (
                      <span
                        className="td-left clickable"
                        style={{ wordBreak: 'break-all' }}
                        onClick={() => {
                          directoryCallback(device.id, item.path);
                        }}
                      >
                        {item.name}/
                      </span>
                    ) : (
                      <span className="td-left" style={{ wordBreak: 'break-all' }}>
                        {item.name}
                      </span>
                    )}
                  </div>

                  <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                    <div
                      className="td-center"
                      style={{ float: 'left', position: 'relative', width: '70%' }}
                    >
                      {new Date(item.modification * 1000).toLocaleString()}
                    </div>
                    <div
                      className="td-right"
                      style={{ float: 'left', position: 'relative', width: '30%' }}
                    >
                      {humanFileSize(item.size)}
                    </div>
                  </div>
                </div>
                {/* // Line 3 */}
                <div
                  className="td-center"
                  style={{
                    verticalAlign: 'middle',
                    float: 'left',
                    position: 'relative',
                    width: '10%',
                  }}
                >
                  <Icon
                    className="clickable"
                    color="red"
                    name="trash alternate outline"
                    size="large"
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <FreeboxDeleteFileModal
        open={open}
        item={item}
        setOpen={setOpen}
        deleteCallback={deleteCallback}
      />
    </>
  );
}

export default FreeboxFileExplorerTableRow;
