import React, { useEffect, useState } from 'react';
import '../../assets/css/Directory.css';
import { useLocation, useNavigationType } from 'react-router';
import { Base64 } from 'js-base64';
import qs from 'qs';
import { serverDelete, zipFolder } from '../../services/Server';
import DirectoryTableGroup from './DirectoryTableGroup';
import { notifError, notifInfo, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function DirectoryTab({ currentDirectory, directoryContent, getDirectory, devices, cloudContent }) {
  const action = useNavigationType();
  const currentLocation = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const [openZip, setOpenZip] = useState(false);
  const [state, setState] = useState({
    directoryCheckedItems: [],
  });

  const handleDelete = (items) => {
    const deletePath = items[0].filepath.substring(
      0,
      items[0].filepath.length - items[0].file.length
    );
    const deleteFiles = items.map((item) => {
      return Base64.encode(encodeURIComponent(item.file), true);
    });
    serverDelete({
      path: Base64.encode(encodeURIComponent(deletePath), true),
      files: deleteFiles,
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.partial) {
            let failed = '';
            response.data.result.forEach((file) => {
              if (!file.success) failed += `, ${file.file}`;
            });
            failed = failed.substring(2);
            notifWarning(`Certains élements n'ont pas pu être supprimés: ${failed}`, {
              autoClose: 6000,
            });
          } else if (response.data.result.length > 1) notifSuccess('Éléments supprimés');
          else notifSuccess(`Supprimé: ${response.data.result[0].file}`);
        } else notifError(`Échec de suppression: ${response.data.message}`);
        getDirectory();
      })
      .catch((e) => {
        notifWarning(`Échec de suppression: ${e.message}`);
      });
  };

  const handleZipFolder = (items) => {
    const infoTimeout = setTimeout(() => notifInfo('Compression en cours ...'), 2000);
    const zipPath = items[0].filepath.substring(0, items[0].filepath.length - items[0].file.length);
    const zipFiles = items.map((item) => {
      return item.file;
    });
    setTimeout(getDirectory, 2000);
    zipFolder({
      path: zipPath,
      files: zipFiles,
    })
      .then((response) => {
        clearTimeout(infoTimeout);
        if (response.status === 200 && response.data.success) notifSuccess('Compression terminée');
        else if (response.status === 200) notifError(`Échec: ${response.data.message}`);
        else notifError('Échec de création du zip');
      })
      .catch(() => {
        notifError('Échec de création du zip');
      })
      .finally(() => {
        getDirectory();
      });
  };

  const checkItem = (item) => {
    const checkedContent = state.directoryCheckedItems;
    let isIn = false;
    checkedContent.forEach((checkedItem, index) => {
      if (checkedItem.file === item.file) {
        isIn = true;
        checkedContent.splice(index, 1);
      }
    });
    if (!isIn) checkedContent.push(item);
    setState((prevState) => ({ ...prevState, directoryCheckedItems: checkedContent }));
  };

  useEffect(() => {
    if (action.toString() === 'POP') {
      const urlQS = qs.parse(currentLocation.search, {
        decoder: (str) => str,
        ignoreQueryPrefix: true,
      }).q;
      const directory =
        currentLocation.state && currentLocation.state.directory
          ? currentLocation.state.directory
          : urlQS || '/';
      getDirectory(directory, false, false);
    }
  }, [currentLocation]);

  useEffect(() => {
    setState({ directoryCheckedItems: [] });
  }, [directoryContent]);

  const prepareData = () => {
    const newItems = [];
    const recentItems = [];
    const oldItems = [];
    const errorItems = [];
    directoryContent.forEach((item) => {
      switch (item.recent) {
        case 'N':
          return newItems.push(item);
        case 'R':
          return recentItems.push(item);
        case 'O':
          return oldItems.push(item);
        default:
          return errorItems.push(item);
      }
    });
    return { new: newItems, recent: recentItems, old: oldItems, error: errorItems };
  };

  const items = prepareData();
  return (
    <DirectoryTableGroup
      devices={devices}
      items={items}
      directoryCallback={getDirectory}
      deleteCallback={handleDelete}
      zipCallback={handleZipFolder}
      cloudContent={cloudContent}
      currentDirectory={currentDirectory}
      checkedItems={state.directoryCheckedItems}
      checkItemCallback={checkItem}
      openDelete={openDelete}
      setOpenDelete={setOpenDelete}
      openZip={openZip}
      setOpenZip={setOpenZip}
    />
  );
}

export default DirectoryTab;
