import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifError, notifInfo, notifSuccess } from '../Tools/NotificationToasts';
import { torrentAddMagnet, torrentAddUrl } from '../../services/Torrent';
import { getLocalServerConfig } from '../../services/Server';

function SearchTableDownloadButton({ item, responsive }) {
  const [state, setState] = useState({
    pending: false,
    downloaded: false,
    error: false,
  });

  const handleDownloadTorrent = () => {
    const infoTimeout = setTimeout(() => notifInfo('Connexion en cours ...'), 2000);
    setState((prevState) => ({ ...prevState, pending: true }));
    ((item.guid || item.link).startsWith('magnet:?') ? torrentAddMagnet : torrentAddUrl)({
      url: item.guid || item.link,
      name: item.title,
      size: item.size,
    })
      .then((response) => {
        clearTimeout(infoTimeout);
        if (response.status === 200 && response.data.success) {
          setState({ pending: false, downloaded: true, error: false });
          notifSuccess('Téléchargement démarré');
        } else {
          setState({ pending: false, downloaded: false, error: true });
          if (response.data.message)
            notifError(`Échec du téléchargement: ${response.data.message}`);
          else notifError('Échec du téléchargement, essayez un autre résultat de recherche');
          console.log(response);
        }
      })
      .catch((e) => {
        setState({ pending: false, downloaded: false, error: true });
        notifError(`Échec du téléchargement: ${e}`);
      });
  };

  const id = encodeURI(item.link);
  return (
    <div style={{ textAlign: 'center' }}>
      {state.pending ? (
        <>
          <Icon
            id={`search-item-download-button-pending-${id}`}
            name="spinner"
            color="green"
            loading
            className="td-center"
            size={responsive ? 'big' : 'large'}
          />
          <Tooltip
            anchorId={`search-item-download-button-pending-${id}`}
            effect="solid"
            place="top"
            content="Tentative de téléchargement ..."
          />
        </>
      ) : (
        <>
          <Icon
            id={`search-item-download-button-${id}`}
            name={state.error ? 'warning circle' : 'download'}
            color={state.downloaded ? 'green' : state.error ? 'red' : 'teal'}
            className="td-center clickable"
            size={responsive ? 'big' : 'large'}
            onClick={() => {
              handleDownloadTorrent();
            }}
          />
          <Tooltip
            anchorId={`search-item-download-button-${id}`}
            effect="solid"
            place="top"
            content={
              state.error
                ? 'Essayez un autre résultat de recherche'
                : `Télécharger vers ${getLocalServerConfig().siteName || 'Ergo'}`
            }
          />
        </>
      )}
    </div>
  );
}

export default SearchTableDownloadButton;
