import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { Base64 } from 'js-base64';
import { notifError, notifInfo, notifSuccess, notifWarning } from '../Tools/NotificationToasts';
import { addDeviceTask } from '../../services/Devices';

function DeviceAddLinkModal({ setOpen, open, device }) {
  const [addLink, setAddLink] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleAddLink = (link) => {
    if (link != null && link.length > 0) {
      notifInfo(`Ajout de la tâche sur ${device.name}...`);
      addDeviceTask({
        device_id: device.id,
        link: Base64.encode(encodeURIComponent(link), true),
        filename: Base64.encode(
          encodeURIComponent(link.substring(link.lastIndexOf('/') + 1)),
          true
        ),
      })
        .then((response) => {
          if (response.data.success) {
            setAddLink('');
            notifSuccess(`Téléchargement démarré vers ${device.name}`);
          } else
            notifError(`Échec de téléchargement vers ${device.name}: ${response.data.message}`);
        })
        .catch((e) => {
          notifError(`Échec de téléchargement vers ${device.name}: ${e.message}`);
        });
    } else notifWarning("Aucun téléchargement n'a été ajouté");
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="chain" color="teal" />
        <br />
        Ajouter un lien http / ftp vers {device.name}
      </Header>
      <Modal.Content>
        Vous pouvez télécharger un fichier directement sur votre {device.type} en copiant le lien
        ci-dessous:
        <br />
        <br />
        <Input
          fluid
          focus
          type="text"
          ref={inputRef}
          placeholder="https://www.site.fr/fichier.zip"
          value={addLink}
          onChange={(event) => setAddLink(event.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleAddLink(addLink);
            setOpen(false);
          }}
        >
          <Icon name="download" /> Télécharger
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeviceAddLinkModal;
