import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';

function CalendarTableHeader({ age, count }) {
  const ageLabel = (() => {
    switch (age) {
      case 'missed':
        return (
          <Label horizontal color="red">
            En attente d&apos;un tracker ({count})
          </Label>
        );
      case 'today':
        return (
          <Label horizontal color="green">
            {`Diffusé aujourd'hui (${count})`}
          </Label>
        );
      case 'soon':
        return (
          <Label horizontal color="orange">
            Diffusion proche ({count})
          </Label>
        );
      case 'later':
        return (
          <Label horizontal color="black">
            Plus tard ({count})
          </Label>
        );
      default:
        return <Label horizontal>Indéterminé</Label>;
    }
  })();

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-left" singleLine>
                {ageLabel}
              </Table.Cell>
              <Table.Cell className="td-left">Série</Table.Cell>
              <Table.Cell className="td-center">Épisode</Table.Cell>
              <Table.Cell className="td-center">
                <Icon name="search" />
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon name="calendar outline" /> Date de diffusion
              </Table.Cell>
              <Table.Cell className="td-center">Chaîne</Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-left">{ageLabel}</Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
    </>
  );
}

export default CalendarTableHeader;
