import React from 'react';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import { cleanDiacritic } from '../Tools/Utils';
import { Media } from '../Tools/AppMedia';
import { getLocalServerConfig } from '../../services/Server';

const formatter = buildFormatter(frenchStrings);
const { REACT_APP_API_URL: apiUrl } = process.env;

function CalendarTableRow({ item, setSearchInput, focusSearchInput, handleTabChange }) {
  const seasonNumber = item.seasonNumber < 10 ? `0${item.seasonNumber}` : item.seasonNumber;
  const episodeNumber = item.episodeNumber < 10 ? `0${item.episodeNumber}` : item.episodeNumber;
  const tmpDate = new Date(Date.parse(item.airDateUtc));
  const airDate = `${tmpDate.getDate()}/${tmpDate.getMonth() + 1}/${tmpDate.getFullYear()}`;

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="calendar-table-row">
              <Table.Cell className="td-center">
                <img
                  className="calendar-table-cell  calendar-table-banner-img"
                  alt=""
                  src={`${apiUrl}/api/v1/tvshows/banner/${item.seriesId}`}
                  width={140}
                  height={35}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${window.origin}/placeholder.png`;
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Popup
                  content="Description sur TheMovieDB"
                  position="right center"
                  trigger={
                    <a
                      href={
                        getLocalServerConfig().tmdbEnabled
                          ? `${apiUrl}/api/v1/tvshows/getTmdbPageByTvdbId/${item.series.tvdbId}`
                          : `https://www.themoviedb.org/search/tv?query=${item.series.title}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span style={{ color: 'black' }}>{item.series.title}</span>{' '}
                      <Icon name="external" size="small" color="teal" />
                    </a>
                  }
                />
              </Table.Cell>
              <Table.Cell className="td-center">
                S<span className="font-weight-bolder">{seasonNumber}</span>E
                <span className="font-weight-bolder">{episodeNumber}</span>
              </Table.Cell>
              <Table.Cell className="td-center">
                <Button
                  size="mini"
                  color="teal"
                  icon="search"
                  label="Chercher"
                  labelPosition="left"
                  onClick={() => {
                    setSearchInput(
                      cleanDiacritic(`${item.series.title} S${seasonNumber}E${episodeNumber}`)
                    );
                    focusSearchInput();
                    handleTabChange('', 'search');
                  }}
                />
              </Table.Cell>
              <Table.Cell className="td-center">
                <span
                  id={`diffusion-date-${item.tvdbId}-${airDate}-S${seasonNumber}E${episodeNumber}`}
                >
                  {airDate}
                </span>
                <Tooltip
                  anchorId={`diffusion-date-${item.tvdbId}-${airDate}-S${seasonNumber}E${episodeNumber}`}
                  effect="solid"
                  place="top"
                  content={<TimeAgo date={Date.parse(item.airDateUtc)} formatter={formatter} />}
                />
              </Table.Cell>
              <Table.Cell className="td-center">{item.series.network}</Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row>
              <Table.Cell>
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div style={{ float: 'left', position: 'relative', width: '45%' }}>
                    <img
                      className="calendar-table-cell"
                      alt=""
                      src={`${apiUrl}/api/v1/tvshows/banner/${item.seriesId}`}
                      width={140}
                      height={35}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${window.origin}/placeholder.png`;
                      }}
                    />
                  </div>
                  <div style={{ float: 'left', position: 'relative', width: '55%' }}>
                    <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                      <div
                        className="td-left"
                        style={{ float: 'left', position: 'relative', width: '50%' }}
                      >
                        S<span className="font-weight-bolder">{seasonNumber}</span>E
                        <span className="font-weight-bolder">{episodeNumber}</span>{' '}
                        <Icon
                          className="clickable"
                          name="search"
                          color="teal"
                          onClick={() => {
                            setSearchInput(
                              cleanDiacritic(
                                `${item.series.title} S${seasonNumber}E${episodeNumber}`
                              )
                            );
                            focusSearchInput();
                            handleTabChange('', 'search');
                          }}
                        />
                      </div>
                      <div
                        className="td-right"
                        style={{ float: 'left', position: 'relative', width: '50%' }}
                      >
                        <span
                          id={`diffusion-date-${item.tvdbId}-${airDate}-S${seasonNumber}E${episodeNumber}`}
                        >
                          {airDate}
                        </span>
                        <Tooltip
                          anchorId={`diffusion-date-${item.tvdbId}-${airDate}-S${seasonNumber}E${episodeNumber}`}
                          effect="solid"
                          place="top"
                          content={
                            <TimeAgo date={Date.parse(item.airDateUtc)} formatter={formatter} />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '85%' }}
                  >
                    <a
                      href={
                        getLocalServerConfig().tmdbEnabled
                          ? `${apiUrl}/api/v1/tvshows/getTmdbPageByTvdbId/${item.series.tvdbId}`
                          : `https://www.themoviedb.org/search/tv?query=${item.series.title}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span style={{ color: 'black' }}>{item.series.title}</span>{' '}
                      <Icon name="external" size="small" color="teal" />
                    </a>
                  </div>
                  <div
                    className="td-right"
                    style={{ float: 'left', position: 'relative', width: '15%' }}
                  >
                    <span>{item.series.network}</span>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
    </>
  );
}
export default CalendarTableRow;
