import React, { useState } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifInfo } from '../Tools/NotificationToasts';
import { Media } from '../Tools/AppMedia';
import { humanFileSize } from '../Tools/Utils';
import DeviceTaskDeleteModal from '../Modal/DeviceTaskDeleteModal';

function FreeboxTableRow({ device, handleTaskAction, item }) {
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const buildActionCell = (i) => {
    switch (i.status) {
      case 'downloading':
        return (
          <>
            <Icon
              id={`task-action-cell-${i.id}`}
              name="stop"
              color="red"
              className="clickable"
              onClick={() => {
                notifInfo('Mise en pause de la tâche...');
                handleTaskAction(device.id, i.id, 'stopped');
                setOpenPopup(false);
              }}
            />
            <Tooltip
              anchorId={`task-action-cell-${i.id}`}
              effect="solid"
              place="right"
              content="Mettre en pause"
            />
          </>
        );
      case 'stopped':
        return (
          <>
            <Icon
              id={`task-action-cell-${item.id}`}
              name="play"
              color="green"
              className="clickable"
              onClick={() => {
                notifInfo('Reprise de la tâche...');
                handleTaskAction(device.id, item.id, 'downloading');
                setOpenPopup(false);
              }}
            />
            <Tooltip
              anchorId={`task-action-cell-${item.id}`}
              effect="solid"
              place="right"
              content="Reprendre"
            />
          </>
        );
      case 'error':
      case 'done':
      case 'seeding':
      default:
        return null;
    }
  };

  const buildActionCellResponsive = (i) => {
    switch (i.status) {
      case 'downloading':
        return (
          <>
            <br />
            <span
              className="clickable clickable-color"
              onClick={() => {
                notifInfo('Mise en pause de la tâche...');
                handleTaskAction(device.id, i.id, 'stopped');
                setOpenPopup(false);
              }}
            >
              <Icon size="large" name="stop" color="red" /> Pause
            </span>
            <br />
            <br />
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon color="red" size="large" name="trash alternate outline" /> Supprimer
            </span>
            <br />
          </>
        );
      case 'stopped':
        return (
          <>
            <br />
            <span
              className="clickable clickable-color"
              onClick={() => {
                notifInfo('Reprise de la tâche...');
                handleTaskAction(device.id, item.id, 'downloading');
                setOpenPopup(false);
              }}
            >
              <Icon size="large" name="play" color="green" /> Reprendre
            </span>
            <br />
            <br />
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon color="red" size="large" name="trash alternate outline" /> Supprimer
            </span>
            <br />
          </>
        );
      case 'error':
      case 'done':
      case 'seeding':
      default:
        return (
          <>
            <br />
            <span
              className="clickable clickable-color"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon
                data-tip
                data-for={`synology-delete-cell-${i.id}`}
                color="red"
                name="trash alternate outline"
              />{' '}
              Supprimer
            </span>
          </>
        );
    }
  };

  const buildStateCell = (i) => {
    switch (i.status) {
      case 'done':
        return <Icon name="check" />;
      case 'stopped':
        return <Icon name="pause" />;
      case 'error':
        return <Icon name="exclamation triangle" color="red" />;
      case 'seeding':
      case 'downloading':
        return <Icon name="cloud download" color="green" />;
      default:
        return null;
    }
  };

  const i = item;
  const percentage = i.rx_pct !== 0 ? `${(i.rx_pct / 100).toFixed(2)} %` : '0.00 %';
  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row
              className={
                i.status === 'paused'
                  ? 'warning'
                  : i.status === 'error'
                    ? 'negative'
                    : i.status === 'downloading'
                      ? 'positive'
                      : i.status === 'waiting'
                        ? 'active'
                        : ''
              }
              as={Table.Row}
            >
              <Table.Cell className="td-center">{buildActionCell(i)}</Table.Cell>
              <Table.Cell className="td-center">{buildStateCell(i)}</Table.Cell>
              <Table.Cell
                style={{ wordBreak: 'break-all' }}
                className={`td-left ${i.status !== 'done' && 'font-weight-normal'}`}
              >
                {i.name}
              </Table.Cell>
              <Table.Cell className="td-center">
                {i.status === 'downloading' &&
                  `${i.rx_rate !== 0 ? `${humanFileSize(i.rx_rate)}/s` : '0'} | ${
                    i.tx_rate !== 0 ? `${humanFileSize(i.tx_rate)}/s` : '0 kb/s'
                  }`}
              </Table.Cell>
              <Table.Cell className="td-center font-weight-bolder">
                {i.status === 'done' ? <Icon color="green" name="check" /> : percentage}
              </Table.Cell>
              <Table.Cell className="td-center">
                {new Date(i.created_ts * 1000).toLocaleString()}
              </Table.Cell>
              <Table.Cell className="td-right font-weight-bolder">
                {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon
                  name="trash alternate outline"
                  color="red"
                  id={`freebox-delete-cell-${i.id}`}
                  className="clickable"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Tooltip
                  anchorId={`freebox-delete-cell-${i.id}`}
                  effect="solid"
                  place="left"
                  content="Supprimer la tâche"
                />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row
              className={
                i.status === 'paused'
                  ? 'warning'
                  : i.status === 'error'
                    ? 'negative'
                    : i.status === 'downloading'
                      ? 'positive'
                      : i.status === 'waiting'
                        ? 'active'
                        : ''
              }
            >
              <Table.Cell>
                {/* // Line 1 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <span
                    style={{ wordBreak: 'break-all' }}
                    className={i.status !== 'finished' && 'font-weight-normal'}
                  >
                    {i.name}
                  </span>
                </div>
                {/* // Line 2 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <span>{buildStateCell(i)}</span>
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '40%' }}
                  >
                    {i.status === 'downloading'
                      ? `${i.rx_rate !== 0 ? `${humanFileSize(i.rx_rate)}/s` : '0'} | ${
                          i.tx_rate !== 0 ? `${humanFileSize(i.tx_rate)}/s` : '0 kb/s'
                        }`
                      : '-'}
                  </div>
                  <div
                    className="td-left font-weight-bolder"
                    style={{ float: 'left', position: 'relative', width: '40%' }}
                  >
                    {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
                  </div>
                  <div
                    className="td-right"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <Popup
                      on={['click']}
                      position="left center"
                      hideOnScroll
                      header="Actions:"
                      size="large"
                      open={openPopup}
                      onOpen={() => setOpenPopup(true)}
                      onClose={() => setOpenPopup(false)}
                      trigger={
                        <Icon
                          className="clickable"
                          name="angle double right"
                          color="teal"
                          size="big"
                        />
                      }
                      content={buildActionCellResponsive(i)}
                    />
                  </div>
                </div>
                {/* // Line 3 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left font-weight-bolder"
                    style={{ float: 'left', position: 'relative', width: '30%' }}
                  >
                    {i.status === 'done' ? (
                      <>
                        <Icon color="green" name="check" /> 100%
                      </>
                    ) : (
                      percentage
                    )}
                  </div>
                  <div
                    className="td-center"
                    style={{ float: 'left', position: 'relative', width: '70%' }}
                  >
                    {new Date(i.created_ts * 1000).toLocaleString()}
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <DeviceTaskDeleteModal
        open={open}
        deviceId={device.id}
        taskId={i.id}
        setOpen={setOpen}
        action="remove"
        handleDeleteTaskAction={handleTaskAction}
      />
    </>
  );
}

export default FreeboxTableRow;
