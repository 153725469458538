import React, { useState, useEffect, useRef } from 'react';
import { Base64 } from 'js-base64';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifInfo, notifSuccess, notifWarning } from '../Tools/NotificationToasts';
import { addDeviceTask } from '../../services/Devices';

function DeviceCell({
  item,
  itemId,
  device,
  deviceDownloaded,
  mobile,
  setOpenPopup,
  directoryCallback,
  hovered,
}) {
  const mounted = useRef(false);
  const [state, setState] = useState({
    pending: false,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleAddDeviceTask = () => {
    if (!hovered) return;
    if (mounted.current) setState({ pending: true });
    if (device.type === 'synology') notifInfo(`Connexion à ${device.name}...`);
    addDeviceTask({
      device_id: device.id,
      link: Base64.encode(encodeURIComponent(item.filepath), true),
      filename: Base64.encode(encodeURIComponent(item.file), true),
    })
      .then((response) => {
        if (response.data.success) notifSuccess(`Téléchargement démarré sur ${device.name}`);
        else notifWarning(`Échec de téléchargement vers ${device.name}: ${response.data.message}`);
        if (mounted.current) {
          if (mobile) setOpenPopup(false);
          directoryCallback();
        }
      })
      .catch((e) => {
        notifWarning(`Échec de téléchargement vers ${device.name}: ${e.message}`);
      })
      .finally(() => {
        if (mounted.current) setState({ pending: false });
      });
  };

  const buildTableDeviceCell = () => {
    return (
      <React.Fragment key={`${device.id}-${itemId}`}>
        {state.pending ? (
          <>
            <Icon
              id={`pending-${device.id}-${itemId}`}
              name="spinner"
              color="green"
              loading
              className="td-center"
              size="large"
            />
            <Tooltip
              anchorId={`pending-${device.id}-${itemId}`}
              effect="solid"
              content={`Tentative de téléchargement vers ${device.name}`}
              place="top"
            />
          </>
        ) : (
          <>
            <span id={`${device.id}-${itemId}`}>
              <Icon
                className="clickable"
                color={deviceDownloaded ? 'grey' : 'teal'}
                size="large"
                name="download"
                onClick={() => handleAddDeviceTask(device)}
                style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
              />{' '}
            </span>
            <Tooltip
              anchorId={`${device.id}-${itemId}`}
              effect="solid"
              content={device.name}
              place="top"
              hidden={!hovered}
            />
          </>
        )}
      </React.Fragment>
    );
  };

  const buildTableDeviceCellResponsive = () => {
    return (
      <React.Fragment key={`${device.id}-${itemId}`}>
        <br />
        <span
          className="clickable"
          onClick={() => {
            handleAddDeviceTask();
          }}
        >
          {state.pending ? (
            <Icon name="spinner" color="green" loading size="large" />
          ) : (
            <Icon color={deviceDownloaded ? 'grey' : 'teal'} size="large" name="download" />
          )}{' '}
          {device.name}
        </span>
        <br />
      </React.Fragment>
    );
  };

  return mobile ? buildTableDeviceCellResponsive() : buildTableDeviceCell();
}

function DevicesCell({ devices, item, directoryCallback, setOpenPopup, mobile, hovered }) {
  return devices.map((device) => {
    let deviceDownloaded = false;
    item.who_downloaded.forEach((downloaded) => {
      if (downloaded.is_user === 0 && downloaded.downloader_id === device.id)
        deviceDownloaded = true;
    });
    const id = Base64.encode(item.file);
    return (
      <DeviceCell
        key={`${device.id}-${id}`}
        directoryCallback={directoryCallback}
        item={item}
        itemId={id}
        device={device}
        hovered={hovered}
        deviceDownloaded={deviceDownloaded}
        setOpenPopup={setOpenPopup}
        mobile={mobile}
      />
    );
  });
}

export default DevicesCell;
