import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import { Checkbox, Icon, Popup, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { getLocalServerConfig } from '../../services/Server';
import { rmBrackets } from '../Tools/Utils';
import { Media } from '../Tools/AppMedia';
import { getRole } from '../../services/Auth';
import { DeleteModal } from '../Modal/DeleteModal';
import { ZipModal } from '../Modal/ZipModal';
import DevicesCell from './DirectoryTableDevicesCell';
import frenchStrings from '../Tools/TimeAgoFrStrings';

const formatter = buildFormatter(frenchStrings);

const { REACT_APP_API_URL: apiUrl } = process.env;

function DirectoryTableRow({
  element,
  directoryCallback,
  deleteCallback,
  zipCallback,
  devices,
  checked,
  checkItemCallback,
  checkedItems,
}) {
  const [open, setOpen] = useState(false);
  const [openZip, setOpenZip] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [hovered, setHovered] = useState(false);
  const id = Base64.encode(unescape(encodeURIComponent(element.filepath)), true);

  const handleRecordDownload = () => {
    setTimeout(() => {
      directoryCallback();
    }, 250);
  };

  const shakeFile = (event, filepath) => {
    const zippedElem = event.target.parentNode.parentNode.parentNode.querySelector(
      `#${Base64.encode(unescape(encodeURIComponent(`${filepath}.zip`)), true)}`
    );
    zippedElem.scrollIntoView({ behavior: 'smooth' });
    zippedElem.className = 'shake';
    setTimeout(() => {
      if (zippedElem !== null) zippedElem.className = '';
    }, 800);
  };

  const buildTableInfoCell = (item) => {
    const users = item.who_downloaded;
    const downloaders = users.map((user) => {
      return (
        <React.Fragment key={`info-tips-${id}-${user.user}`}>
          {user.user} | <TimeAgo date={user.timestamp} formatter={formatter} />
          <br />
        </React.Fragment>
      );
    });
    return (
      <>
        <Media greaterThanOrEqual="lg">
          <Icon
            id={`info-cell-desktop-${id}`}
            name="tasks"
            color={item.user_downloaded ? 'teal' : 'grey'}
          />
          <Tooltip anchorId={`info-cell-desktop-${id}`} effect="solid" place="right">
            {downloaders}
          </Tooltip>
        </Media>
        <Media lessThan="lg">
          <Icon
            id={`info-cell-${id}`}
            name="tasks"
            color={item.user_downloaded ? 'teal' : 'grey'}
            size="small"
          />
          <Tooltip anchorId={`info-cell-${id}`} effect="solid" place="right">
            {downloaders}
          </Tooltip>
        </Media>
      </>
    );
  };

  const desktopRow = (item) => {
    const itemDate = new Date(item.date);
    const date = item.date
      ? `${itemDate.getDate() < 10 ? '0' : ''}${itemDate.getDate()}/${
          itemDate.getMonth() < 9 ? '0' : ''
        }${itemDate.getMonth() + 1} - ${
          itemDate.getHours() < 10 ? '0' : ''
        }${itemDate.getHours()}:${itemDate.getMinutes() < 10 ? '0' : ''}${itemDate.getMinutes()}`
      : '';
    return item.file.includes('-zip-in-progress-do-NOT-download.zip') && item.type === 'file' ? (
      <>
        <td className="td-center" />
        <td className="td-center" />
        <td className="td-center">
          <Icon id={`zipping-cell-${id}`} name="spinner" color="green" loading />
          <Tooltip
            anchorId={`zipping-cell-${id}`}
            effect="solid"
            place="right"
            content="Compression en cours"
          />
        </td>
        <td className="td-left">
          <span className="text-success">{rmBrackets(item.file)}</span>
        </td>
        <td className="td-center" />
        <td className="td-center">{item.ext}</td>
        <td className="td-center">
          <span className="grey-text">{date}</span>
        </td>
        <td className="td-right">
          <span className="font-weight-bolder">{item.size}</span>
        </td>
        <td className="td-center" />
      </>
    ) : (
      <>
        <td className="td-center">
          {item.file !== 'Répertoire Parent' && (
            <Checkbox
              disabled={item.zipping}
              checked={checked}
              onChange={() => checkItemCallback(item)}
            />
          )}
        </td>
        <td className="td-center">
          {item.type === 'file' && item.who_downloaded.length > 0 && buildTableInfoCell(item)}
        </td>
        <td className="td-center">
          {!checkedItems &&
            item.type === 'dir' &&
            item.file !== 'Répertoire Parent' &&
            getLocalServerConfig().zipEnabled &&
            (item.zipping ? (
              <>
                <Icon id={`zipping-cell-${id}`} name="file archive outline" color="orange" />
                <Tooltip
                  anchorId={`zipping-cell-${id}`}
                  effect="solid"
                  place="right"
                  content="Compression en cours"
                />
              </>
            ) : (
              <>
                <Icon
                  id={`zip-cell-${id}`}
                  className="clickable"
                  name="file archive outline"
                  color={item.zipped ? 'yellow' : 'green'}
                  onClick={(e) => {
                    if (item.zipped) shakeFile(e, item.filepath);
                    else setOpenZip(true);
                  }}
                  style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                />
                <Tooltip
                  anchorId={`zip-cell-${id}`}
                  effect="solid"
                  place="right"
                  content={item.zipped ? 'Zip déjà existant' : 'Zipper ce dossier'}
                />
              </>
            ))}
        </td>
        <td className="td-left">
          {item.type === 'dir' ? (
            <span
              className="clickable clickable-folder"
              onClick={() => directoryCallback(item.filepath)}
            >
              {rmBrackets(item.file)}
              {item.file !== 'Répertoire Parent' && '/'}{' '}
              {rmBrackets(item.file) !== item.file && (
                <span>
                  <Icon
                    name="tag"
                    color="grey"
                    id={`real-name-cell-${id}`}
                    style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                  />
                  <Tooltip
                    anchorId={`real-name-cell-${id}`}
                    effect="solid"
                    place="top"
                    content={item.file}
                  />
                </span>
              )}
              {item.delete && (
                <>
                  <Icon
                    id={`imminent-delete-cell-${id}`}
                    className="blink"
                    name="trash alternate outline"
                    color="red"
                  />
                  <Tooltip
                    anchorId={`imminent-delete-cell-${id}`}
                    effect="solid"
                    place="right"
                    content="Suppression imminente"
                  />
                </>
              )}
            </span>
          ) : (
            <>
              <a
                download
                href={`${apiUrl}/api/v1/server/download?key=${Base64.encode(
                  encodeURIComponent(JSON.stringify({ url: item.filepath, filename: item.file })),
                  true
                )}`}
                color={item.user_downloaded ? 'grey' : 'red'}
                className={item.user_downloaded ? 'grey-text' : ''}
                onMouseDown={() => {
                  handleRecordDownload();
                }}
              >
                {rmBrackets(item.file)}
              </a>{' '}
              {rmBrackets(item.file) !== item.file && (
                <span>
                  <Icon
                    name="tag"
                    color="grey"
                    id={`real-name-cell-${id}`}
                    style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                  />
                  <Tooltip
                    anchorId={`real-name-cell-${id}`}
                    effect="solid"
                    place="top"
                    content={item.file}
                  />
                </span>
              )}
              {['.mp3', '.mp4', '.ogg', '.avi', '.mkv', '.mov'].includes(item.ext) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/stream?q=${Base64.encode(
                    encodeURIComponent(JSON.stringify({ url: item.filepath, filename: item.file })),
                    true
                  )}`}
                >
                  <span>
                    <Icon
                      name="play circle"
                      color="green"
                      id={`stream-cell-${id}`}
                      style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                    />
                    <Tooltip
                      anchorId={`stream-cell-${id}`}
                      effect="solid"
                      place="top"
                      content="Prévisualiser"
                    />
                  </span>
                </a>
              )}{' '}
              {item.delete && (
                <>
                  <Icon
                    id={`imminent-delete-cell-${id}`}
                    className="blink"
                    name="trash alternate outline"
                    color="red"
                  />
                  <Tooltip
                    anchorId={`imminent-delete-cell-${id}`}
                    effect="solid"
                    place="right"
                    content="Suppression imminente"
                  />
                </>
              )}
            </>
          )}
        </td>
        <td className="td-center">
          {item.type === 'file' && (
            <DevicesCell
              directoryCallback={directoryCallback}
              devices={devices}
              item={item}
              hovered={hovered && !checkedItems}
            />
          )}
        </td>
        <td className="td-center">
          {item.type === 'dir' ? (
            <Icon name="folder open outline" />
          ) : item.ext.length > 6 ? (
            `${item.ext.substring(0, 6)}...`
          ) : (
            item.ext
          )}
        </td>
        <td className="td-center">
          <span className="grey-text">{date}</span>
        </td>
        <td className="td-right">
          <span className="font-weight-bolder">{item.size}</span>
        </td>
        <td className="td-center">
          {!checkedItems &&
            item.file !== 'Répertoire Parent' &&
            (item.zipping ? (
              <>
                <Icon id={`delete-cell${id}`} name="trash alternate outline" color="black" />
                <Tooltip
                  anchorId={`delete-cell${id}`}
                  effect="solid"
                  place="left"
                  content="Élément en cours d'utilisation"
                />
              </>
            ) : (
              <>
                <Icon
                  id={`prevent-delete-cell${id}`}
                  className="clickable"
                  name="trash alternate outline"
                  color={item.recent === 'N' && getRole() < 2 ? 'blue' : 'red'}
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                />
                <Tooltip
                  anchorId={`prevent-delete-cell${id}`}
                  effect="solid"
                  place="left"
                  content={
                    item.recent === 'N' && getRole() < 2
                      ? 'Supprimer le fichier récent du serveur'
                      : 'Supprimer'
                  }
                  style={!hovered ? { visibility: 'hidden' } : { visibility: 'visible' }}
                />
              </>
            ))}
        </td>
      </>
    );
  };

  const mobileRow = (item) => {
    const itemDate = new Date(item.date);
    const date = item.date
      ? `${itemDate.getDate() < 10 ? '0' : ''}${itemDate.getDate()}/${
          itemDate.getMonth() < 9 ? '0' : ''
        }${itemDate.getMonth() + 1} - ${
          itemDate.getHours() < 10 ? '0' : ''
        }${itemDate.getHours()}:${itemDate.getMinutes() < 10 ? '0' : ''}${itemDate.getMinutes()}`
      : '';
    return item.file.includes('-zip-in-progress-do-NOT-download.zip') && item.type === 'file' ? (
      <>
        <td>
          <div>
            <div className="left">
              <span style={{ wordBreak: 'break-all' }} className="text-success">
                {rmBrackets(item.file)}
              </span>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ float: 'left', position: 'relative', width: '5%' }} />
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span style={{ wordBreak: 'break-all' }}>
                <Icon name="file outline" size="small" />{' '}
                {item.ext.length > 6 ? `${item.ext.substring(0, 6)}...` : item.ext}
              </span>
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span className="grey-text">{date}</span>
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span>{item.size}</span>
            </div>
          </div>
        </td>
        <td>
          <Icon id={`zipping-cell-${id}`} size="large" name="spinner" color="green" loading />
          <Tooltip
            anchorId={`zipping-cell-${id}`}
            effect="solid"
            place="left"
            content="Compression en cours"
          />
        </td>
        <td />
      </>
    ) : (
      <>
        <td>
          <div>
            <div className="left">
              {item.type === 'dir' ? (
                <span
                  style={{ wordBreak: 'break-all' }}
                  className="clickable clickable-folder"
                  onClick={() => {
                    directoryCallback(item.filepath);
                  }}
                >
                  {rmBrackets(item.file)}
                  {item.file !== 'Répertoire Parent' && '/'}
                </span>
              ) : (
                <>
                  <a
                    style={{ wordBreak: 'break-all' }}
                    download
                    href={`${apiUrl}/api/v1/server/download?key=${Base64.encode(
                      encodeURIComponent(
                        JSON.stringify({
                          url: item.filepath,
                          filename: item.file,
                        })
                      ),
                      true
                    )}`}
                    color={item.user_downloaded ? 'grey' : 'red'}
                    className={item.user_downloaded ? 'grey-text' : ''}
                    onMouseDown={() => {
                      handleRecordDownload();
                    }}
                  >
                    {rmBrackets(item.file)}
                  </a>{' '}
                  {['.mp3', '.mp4', '.ogg', '.avi', '.mkv', '.mov'].includes(item.ext) && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/stream?q=${Base64.encode(
                        encodeURIComponent(
                          JSON.stringify({ url: item.filepath, filename: item.file })
                        ),
                        true
                      )}`}
                    >
                      <span>
                        <Icon name="play circle" color="green" id={`stream-cell-${id}`} />
                        <Tooltip
                          anchorId={`stream-cell-${id}`}
                          effect="solid"
                          place="top"
                          content="Prévisualiser"
                        />
                      </span>
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ float: 'left', position: 'relative', width: '5%' }}>
              {item.type === 'file' && item.who_downloaded.length > 0 && buildTableInfoCell(item)}
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              {item.type === 'dir' ? (
                <Icon name="folder open" size="small" />
              ) : (
                <span style={{ wordBreak: 'break-all' }}>
                  <Icon name="file outline" size="small" />{' '}
                  {item.ext.length > 6 ? `${item.ext.substring(0, 6)}...` : item.ext}
                </span>
              )}{' '}
              {item.delete && (
                <>
                  <Icon
                    id={`imminent-delete-cell-${id}`}
                    className="blink"
                    name="trash alternate outline"
                    color="red"
                  />
                  <Tooltip
                    anchorId={`imminent-delete-cell-${id}`}
                    effect="solid"
                    place="right"
                    content="Suppression imminente"
                  />
                </>
              )}
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span className="grey-text">{date}</span>
            </div>
            <div style={{ float: 'left', position: 'relative', width: '31.66%' }}>
              <span>{item.size}</span>
            </div>
          </div>
        </td>
        <td>
          {item.type === 'dir' ? (
            item.file !== 'Répertoire Parent' ? (
              <>
                <Icon
                  id={`zip-cell-${id}`}
                  className="clickable"
                  size="large"
                  name="file archive outline"
                  color={item.zipped ? 'yellow' : 'green'}
                  onClick={(e) => {
                    if (item.zipped) shakeFile(e, item.filepath);
                    else setOpenZip(true);
                  }}
                />
                <Tooltip
                  anchorId={`zip-cell-${id}`}
                  effect="solid"
                  place="left"
                  content="Zipper ce dossier"
                />
              </>
            ) : (
              ''
            )
          ) : (
            devices.length > 0 && (
              <Popup
                on={['click']}
                position="bottom center"
                hideOnScroll
                header="Télécharger vers:"
                size="large"
                open={openPopup}
                onOpen={() => setOpenPopup(true)}
                onClose={() => setOpenPopup(false)}
                trigger={<Icon className="clickable" name="download" color="teal" size="large" />}
                content={
                  <DevicesCell
                    directoryCallback={directoryCallback}
                    devices={devices}
                    item={item}
                    mobile
                    setOpenPopup={setOpenPopup}
                    hovered
                  />
                }
              />
            )
          )}
        </td>
        <td>
          {item.file !== 'Répertoire Parent' &&
            (item.recent === 'N' && getRole() < 2 ? (
              <>
                <Icon
                  id={`prevent-delete-cell${id}`}
                  className="clickable"
                  name="trash alternate outline"
                  color="blue"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Tooltip
                  anchorId={`prevent-delete-cell${id}`}
                  effect="solid"
                  place="left"
                  content="Supprimer le fichier récent du serveur"
                />
              </>
            ) : (
              <>
                <Icon
                  id={`delete-cell${id}`}
                  className="clickable"
                  name="trash alternate outline"
                  color="red"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Tooltip
                  anchorId={`delete-cell${id}`}
                  effect="solid"
                  place="left"
                  content="Supprimer du serveur"
                />
              </>
            ))}
        </td>
      </>
    );
  };

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row
              id={id}
              active={checked}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {desktopRow(element)}
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? <Table.Row id={id}>{mobileRow(element)}</Table.Row> : null;
        }}
      </Media>
      <DeleteModal
        open={open}
        item={element}
        recent={element.recent === 'N'}
        setOpen={setOpen}
        handleDelete={deleteCallback}
      />
      <ZipModal open={openZip} item={element} setOpen={setOpenZip} handleZipFolder={zipCallback} />
    </>
  );
}

export default DirectoryTableRow;
