import React, { useState } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Button, Header, Icon, Modal, Progress, Segment } from 'semantic-ui-react';
import { getLocalServerConfig, uploadFile } from '../../services/Server';
import { notifError, notifInfo, notifSuccess } from './NotificationToasts';

function DropZoneFileUploads({ dragOver, dragOverCallback, reloadDirectoryCallback }) {
  const [state, setState] = useState({
    files: [],
    uploadInProgress: false,
  });

  const onDrop = (files) => {
    setState((prevState) => ({
      ...prevState,
      uploadInProgress: true,
      files: files.map((file) => Object.assign(file, { progress: 0 })),
    }));

    const uploads = files.map((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      return uploadFile(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000 * 30,
        onUploadProgress: (progressEvent) => {
          files[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setState((prevState) => ({
            ...prevState,
            files,
          }));
        },
      });
    });

    axios
      .all(uploads)
      .then((response) => {
        const result = { success: [], error: [] };
        response.forEach((res) => {
          if (res.status === 200) {
            if (res.data.success) result.success = result.success.concat(res.data.success);
            else result.error = result.error.concat(res.data.message);
          }
        });
        notifInfo("Finalisation de l'upload");
        setTimeout(
          (done) => {
            setState((prevState) => ({
              ...prevState,
              files: [],
              uploadInProgress: false,
            }));
            dragOverCallback(false);
            reloadDirectoryCallback();
            if (done.error.length > 0 || done.success.length > 0) {
              if (done.error.length > 0)
                notifError(`Erreur lors de l'upload: ${done.error.join(', ')}`, {
                  autoClose: 8000,
                });
              if (done.success.length > 0)
                notifSuccess(`Uploadé avec succès: ${done.success.join(', ')}`);
            } else notifError("Erreur lors de l'upload");
          },
          2000,
          result
        );
      })
      .catch((e) => {
        notifError(`Échec de l'upload, réessayez ultérieurement: ${e}`);
        setState((prevState) => ({
          ...prevState,
          files: [],
          uploadInProgress: false,
        }));
      });
  };

  const items = state.files.map((file) => (
    <div className="p-2 text-left file-upload-item" key={file.name}>
      <Progress percent={file.progress} color="green" active>
        {file.name}
      </Progress>
    </div>
  ));

  return (
    <Modal basic open={dragOver} onClose={() => dragOverCallback(false)}>
      <Modal.Header>
        Upload de fichiers
        {getLocalServerConfig().torrentEnabled !== 'disabled' ? ' / torrents' : ''}
      </Modal.Header>
      <Modal.Content>
        <Dropzone disabled={state.uploadInProgress} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <Segment placeholder className="clickable">
                <Header icon>
                  {state.uploadInProgress ? (
                    <>
                      <Icon loading name="spinner" color="blue" />
                      <p>Transfert en cours...</p>
                      <p>Merci de patienter avant d&apos;ajouter d&apos;autres fichiers</p>
                    </>
                  ) : (
                    <>
                      <Icon name="upload" color="blue" />
                      <p>
                        Glissez-déposez vos
                        {getLocalServerConfig().torrentEnabled !== 'disabled'
                          ? ' torrents /'
                          : ''}{' '}
                        fichiers ou cliquez ici
                      </p>
                    </>
                  )}
                </Header>
                <input
                  {...getInputProps()}
                  type="file"
                  id="input-file-now"
                  className="file_upload"
                />
              </Segment>
            </div>
          )}
        </Dropzone>
        <div className="d-flex flex-column">{items}</div>
        <br />
        <Modal.Actions className="td-right">
          <Button
            basic
            inverted
            onClick={(e) => {
              e.stopPropagation();
              dragOverCallback(false);
            }}
          >
            <Icon name="close" />
            Fermer
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
}

export default DropZoneFileUploads;
