import { Base64 } from 'js-base64';
import Axios from './Axios';

export const getDirectoryList = (path) =>
  Axios.get(`/api/v1/server/list/?query=${Base64.encode(encodeURIComponent(path))}`);
export const getServerInfo = () => Axios.get('/api/v1/server/infos/');
export const getServerConfig = () => Axios.get('/api/v1/server/config/');
export const getServerStatus = () => Axios.get('/api/v1/server/status/');
export const getServerNotifications = () => Axios.get('/api/v1/server/notifications/');
export const zipFolder = (params) =>
  Axios.post('/api/v1/server/zip/', params, { timeout: 60000 * 30 }); // path, [file,...]
export const serverDelete = (params) => Axios.delete('/api/v1/server/delete/', { data: params }); // base64_path, [base64_filename,...]
export const uploadFile = (params, config) => Axios.post('/api/v1/server/upload/', params, config); //
export const sendBug = (params) => Axios.post('/api/v1/server/bug/', params); // bug
export const contactForm = (params) => Axios.post('/api/v1/server/contact/', params); // token, from, name, subject, message
export const forgotPassword = (params) =>
  Axios.post('/api/v1/server/account/resetPassword/', params); // token, email
export const resetPassword = (params) =>
  Axios.post('/api/v1/server/account/resetPassword/confirm/', params); // token, resetToken, password
export const updatePassword = (params) =>
  Axios.post('/api/v1/server/account/updatePassword/', params); // currentPassword, newPassword
export const emailValidation = (params) =>
  Axios.post('/api/v1/server/account/emailValidation/', params); // email, token
export const updateNickname = (params) =>
  Axios.post('/api/v1/server/account/updateNickname/', params); // nickname
export const updateNotificationToken = (params) =>
  Axios.post('/api/v1/server/account/updateNotificationToken/', params); // nickname
export const enableNotificationToken = (params) =>
  Axios.post('/api/v1/server/account/enableNotificationToken/', params); // user_id, enabled
export const enableNotificationEmail = (params) =>
  Axios.post('/api/v1/server/account/enableNotificationEmail/', params); // user_id, enabled

export const notifyUserGranted = (params) => Axios.post('/api/v1/server/email/granted/', params); // user_id

export const setServerConfig = ({
  siteName,
  sonarrEnabled,
  subscriptionsEnabled,
  tmdbEnabled,
  tmdbBaseUrl,
  torrentSearchEnabled,
  torrentEnabled,
  torrentAutoDeleteOlderDays,
  devicesEnabled,
  freeboxEnabled,
  synologyEnabled,
  vpnCheckerEnabled,
  zipEnabled,
}) => {
  localStorage.setItem('siteName', `${siteName}`);
  localStorage.setItem('sonarrEnabled', `${sonarrEnabled}`);
  localStorage.setItem('subscriptionsEnabled', `${subscriptionsEnabled}`);
  localStorage.setItem('tmdbEnabled', `${tmdbEnabled}`);
  localStorage.setItem('tmdbBaseUrl', `${tmdbBaseUrl}`);
  localStorage.setItem('torrentSearchEnabled', `${torrentSearchEnabled}`);
  localStorage.setItem('torrentEnabled', `${torrentEnabled}`);
  localStorage.setItem('torrentAutoDeleteOlderDays', `${torrentAutoDeleteOlderDays}`);
  localStorage.setItem('devicesEnabled', `${devicesEnabled}`);
  localStorage.setItem('freeboxEnabled', `${freeboxEnabled}`);
  localStorage.setItem('synologyEnabled', `${synologyEnabled}`);
  localStorage.setItem('vpnCheckerEnabled', `${vpnCheckerEnabled}`);
  localStorage.setItem('zipEnabled', `${zipEnabled}`);
};

export const getLocalServerConfig = () => {
  return {
    siteName: localStorage.getItem('siteName'),
    sonarrEnabled: localStorage.getItem('sonarrEnabled') === 'true',
    subscriptionsEnabled: localStorage.getItem('subscriptionsEnabled') === 'true',
    tmdbEnabled: localStorage.getItem('tmdbEnabled') === 'true',
    tmdbBaseUrl: localStorage.getItem('tmdbBaseUrl'),
    torrentSearchEnabled: localStorage.getItem('torrentSearchEnabled') === 'true',
    torrentEnabled: localStorage.getItem('torrentEnabled'),
    torrentAutoDeleteOlderDays: localStorage.getItem('torrentAutoDeleteOlderDays'),
    devicesEnabled: localStorage.getItem('devicesEnabled') === 'true',
    freeboxEnabled: localStorage.getItem('freeboxEnabled') === 'true',
    synologyEnabled: localStorage.getItem('synologyEnabled') === 'true',
    vpnCheckerEnabled: localStorage.getItem('vpnCheckerEnabled') === 'true',
    zipEnabled: localStorage.getItem('zipEnabled') === 'true',
  };
};
